import axios from "axios";
import customSelectInit from "../components/custom-select.js";
import SimpleBar from "simplebar";

const getDataInit = ()=> {


    // if (window.location.search.includes('%2C')) {
    //     console.log('true')
    //     window.history.pushState('', '',  window.location.search.replace(/%2C/g, ","));
    // }

    const body = document.querySelector('body');
    const queryResult = {};
    queryResult['page'] = [1];
    function getSearchParameters() {
        let prmStr = window.location.search.substring(1);
        prmStr = prmStr.replace(/%2C/g, ",");
        return prmStr != null && prmStr !== "" ? transformToAssocArray(prmStr) : {};
    }

    function transformToAssocArray(prmStr) {
        let params = {};
        let prmArr = prmStr.split("&");
        for (let i = 0; i < prmArr.length; i++) {
            let tmpArr = prmArr[i].split("=");
            params[tmpArr[0]] = tmpArr[1];
        }
        return params;
    }

    function getResponseParams(objectResult) {
        const responseParams = getSearchParameters();
        for (let param in responseParams) {
            if (Object.prototype.hasOwnProperty.call(responseParams, param)) {
                objectResult[param] = (responseParams[param]).split(',');
            }
        }
    }

    function objectToQueryString(obj) {
        let queryString = '';

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {

                let values = null;
                if (key === 'page') {
                    values = obj[key];
                } else {
                    values = obj[key].join(',');
                }

                queryString += `&${encodeURIComponent(key)}=${encodeURIComponent(values)}`;
            }
        }
        // Удаляем первый символ "&"
        queryString = queryString.substring(1);

        return queryString;
    }

    function getFiltersOpenList() {
        const filtersOpenListFromStorage = localStorage.getItem('filtersOpenList');
        if (filtersOpenListFromStorage) {
            const filtersOpenList = filtersOpenListFromStorage.split(',');

            filtersOpenList.forEach((item) => {
                const filterBlock = document.querySelector(`[data-item="${item}"]`);
                if (filterBlock) {
                    filterBlock.classList.add('open-submenu');
                }
            });
        }


        const filterMobileSelected = localStorage.getItem("filterMobileSelected");

        if (filterMobileSelected) {
            const filterMenuItemElms = document.querySelectorAll(`.filter__menu_item`);
            filterMenuItemElms.forEach((item) => {
                if (item.getAttribute('data-item') === filterMobileSelected) {
                    item.classList.add('open-submenu');
                } else {
                    item.classList.remove('open-submenu');
                }
            });


            const filterClearBtn = document.querySelector('.filter-clear');
            const filterBackBtn = document.querySelector('.filter-back');
            const filterDoneBtn = document.querySelector('.filter-done');
            const filterClearAllBtn = document.querySelector('.filter-clear-all');

            [filterBackBtn, filterClearBtn].forEach((item) => {
                item.classList.remove('d-none');
            });
            [filterDoneBtn, filterClearAllBtn].forEach((item) => {
                item.classList.add('d-none');
            });

        }




        const wrapWithScroll = document.querySelectorAll('.wrap--custom');
        if (wrapWithScroll.length > 0) {
            wrapWithScroll.forEach((item) => {
                const simpleBar = new SimpleBar(item);
                simpleBar.recalculate();
            });

        }

        const mobileFilterTrigger = localStorage.getItem("mobileFilterTrigger");
        const filtersOpenSubmenu =  localStorage.getItem("filtersOpenSubmenu");

        if (mobileFilterTrigger === 'true') {
            document.querySelector('.filter__block_body').classList.add('open');
            document.querySelector('.filter__block').classList.add('filter__block--fixed', 'mobile__filter-open');
            document.querySelector('.page__sidebar').classList.add('sidebar--ordered', 'page__sidebar--fixed');
            if (filtersOpenSubmenu === 'true') {
                document.querySelector('.filter__menu').classList.add('submenu-open');
            }
        }



        const filterItemElms = document.querySelectorAll('.filter__menu_item');

        filterItemElms.forEach((item) => {

            let optionsList = [];
            const filterItemOptionsElm = item.querySelector('.filter__menu_item-options');
            const filterItemActiveElms = item.querySelectorAll('.current');

            if (filterItemActiveElms.length > 0) {
                item.classList.add('has--options');
                filterItemActiveElms.forEach((item) => {
                    optionsList.push(item.textContent.replace(/\s/g, ''));
                });

                filterItemOptionsElm.textContent = optionsList.join(', ');
            }
        });


    }


    const paginationBlockElm = document.querySelector('.pagination__block');
    if (paginationBlockElm) {
        document.addEventListener("click", event => {

            const target = event.target.classList.contains('page-link');
            if (!target) return;

            event.preventDefault();
            body.classList.add('content--loading');
            getResponseParams(queryResult);

            queryResult['page'] = event.target.getAttribute('href').split('page=')[1];
            let queryString = objectToQueryString(queryResult).replace(/%2C/g, ",");

            axios({
                method: 'get',
                url: '?' + queryString,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept-Language': document.documentElement.lang,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                },
            })
                .then(function (response) {
                    window.history.pushState('', '', '?' + queryString);
                    setTimeout(function () {
                        body.classList.remove('content--loading');
                        if (response.data.success === true && response.data.text) {
                            document.querySelector('.quantity__block').innerHTML = response.data.text;
                        }
                        if (response.data.success === true && response.data.filterView) {
                            document.querySelector('.filter__block_container').innerHTML = response.data.filterView;
                            getFiltersOpenList();
                            customSelectInit('.mobile__sort_trigger .custom__select');
                        }
                        document.querySelector('.products__list_wrapper').innerHTML = response.data.htmlView;
                        window.scrollTo(0, 0);
                        // document.querySelector('.header').scrollIntoView({ block: "start" });
                    }, 200);
                    // setTimeout(function () {
                    //     // document.querySelector('.header').scrollIntoView({ block: "start" });
                    //     window.scrollTo(0, 0);
                    // }, 400);
                })
                .catch(function (error) {
                    console.log(error)
                });


        });
    }


    function axiosRequest(queryString) {


        let newUrl = null;
        if (queryString === '') {
            newUrl = '';
        } else {
            newUrl = '?' + queryString;
        }

        axios({
            method: 'get',
            url: newUrl,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept-Language': document.documentElement.lang,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
            .then(function (response) {
                window.history.pushState('', '', '?' + queryString);
                setTimeout(function () {
                    body.classList.remove('content--loading');
                    if (response.data.success === true && response.data.text) {
                        document.querySelector('.quantity__block').innerHTML = response.data.text;
                    }
                    if (response.data.success === true && response.data.filterView) {
                        document.querySelector('.filter__block_container').innerHTML = response.data.filterView;
                        getFiltersOpenList();
                        customSelectInit('.mobile__sort_trigger .custom__select');
                    }
                    document.querySelector('.products__list_wrapper').innerHTML = response.data.htmlView;
                    window.scrollTo(0, 0);
                    // document.querySelector('.header').scrollIntoView({ block: "start" });
                    // document.querySelector('.goods_filter_block').innerHTML = response.data.filter;

                }, 200);
                // setTimeout(function () {
                //     window.scrollTo(0, 0);
                //     // document.querySelector('.header').scrollIntoView({ block: "start" });
                //     // document.querySelector('.goods_filter_block').innerHTML = response.data.filter;
                // }, 400);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    function getData(key, value) {

        body.classList.add('content--loading');

        getResponseParams(queryResult);
        queryResult[key] = [value];
        queryResult['page'] = [1];

        let queryString = objectToQueryString(queryResult).replace(/%2C/g, ",");

        axiosRequest(queryString);

    }

    function setQueryResult(objectsObject) {

        body.classList.add('content--loading');
        getResponseParams(queryResult);


        for (let key in objectsObject) {
            if (Object.prototype.hasOwnProperty.call(objectsObject, key)) {
                queryResult[key] = objectsObject[key];
                if (queryResult[key].length === 0) {
                    delete queryResult[key];
                }
            }
        }

        queryResult['page'] = [1];

        let queryString = objectToQueryString(queryResult).replace(/%2C/g, ",");

        axiosRequest(queryString);
    }

    return {
        getResponseParams,
        getData,
        setQueryResult,
        axiosRequest,
    };

}

export default getDataInit;
