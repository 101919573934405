import animationInit from './animation';
import getDataInit from "./get-data";

// import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

const filtersInit = ()=> {

    const { slideUp, slideDown, fadeOut, fadeIn } = animationInit();
    const { setQueryResult, getResponseParams, axiosRequest } = getDataInit();

    const buttonsShowHide = (filterBlockElm) => {

        const linkButtonElm = filterBlockElm.querySelector('.button--link');
        const linkButtonCloseElm = filterBlockElm.querySelector('[data-close-filter]');
        const checkedElms = filterBlockElm.querySelectorAll('.checked');

        if (checkedElms.length > 0) {
            linkButtonElm.classList.remove('d-none');
            linkButtonCloseElm.classList.add('d-none');
        } else {
            linkButtonElm.classList.add('d-none');
            linkButtonCloseElm.classList.remove('d-none');
        }

    }

    const filterHomeInit = () => {

        const filterOpenTriggerElms = document.querySelectorAll('[data-show-filter]');
        const filterCloseTriggerElms = document.querySelectorAll('[data-close-filter]');
        const listFilterElm = document.querySelector('.list--filter');

        filterOpenTriggerElms.forEach((item) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();

                const filterBlockElm = document.querySelector('.'+item.dataset.filter);
                filterBlockElm.classList.remove('d-none');
                listFilterElm.classList.add('d-none');

                const windowWidth = window.innerWidth;
                if (windowWidth < 1200) {
                    filterBlockElm.scrollIntoView({ block: "start" });
                } else {
                    filterBlockElm.scrollIntoView({ block: "center" });
                }
            });
        });

        filterCloseTriggerElms.forEach((item) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();

                const filterBlockElm = item.closest('.filter__block');
                filterBlockElm.classList.add('d-none');
                listFilterElm.classList.remove('d-none');
            });
        });


        const filtersList = document.querySelectorAll('.filter__item');
        let snippet = [];
        filtersList.forEach((item) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();


                const filterBlockElm = item.closest('.filter__block');
                const linkButtonElm = filterBlockElm.querySelector('.button--link');
                const urlFromButton = linkButtonElm.dataset.href;
                let url = '';

                if (item.classList.contains('checked')) {
                    item.classList.remove('checked');
                    const index = snippet.indexOf(item.dataset.snippet);
                    snippet.splice(index, 1);
                } else {
                    item.classList.add('checked');
                    snippet.push(item.dataset.snippet);
                }

                url = urlFromButton + snippet.join(',');

                buttonsShowHide(filterBlockElm);

                linkButtonElm.setAttribute('href', url);
            });
        });

    }


    const filterSidebarInit = () => {

        const body = document.querySelector('body');
        // const filterOpenTriggerElms = document.querySelectorAll('.filter__menu_item_trigger');
        // const filterSubmenuItemElms = document.querySelectorAll('[data-filter-item]');
        const filterItemElms = document.querySelectorAll('.filter__menu_item');

        const filterDoneBtn = document.querySelector('.filter-done');
        const filterChooseBtn = document.querySelector('.filter-choose');
        const filterBackBtn = document.querySelector('.filter-back');
        const filterClearBtn = document.querySelector('.filter-clear');
        const filterClearAllBtn = document.querySelector('.filter-clear-all');

        let filtersOpenList = [];

        localStorage.removeItem("filtersOpenList");
        localStorage.removeItem("filterMobileSelected");
        localStorage.removeItem("filtersOpenSubmenu");
        localStorage.removeItem("mobileFilterTrigger");

        const showFilterButton = () => {

            const filterClearBtn = document.querySelector('.filter-clear');
            const filterBackBtn = document.querySelector('.filter-back');
            const filterDoneBtn = document.querySelector('.filter-done');
            const filterClearAllBtn = document.querySelector('.filter-clear-all');

            [filterBackBtn, filterClearBtn].forEach((item) => {
                item.classList.remove('d-none');
            });
            [filterDoneBtn, filterClearAllBtn].forEach((item) => {
                item.classList.add('d-none');
            });
        }

        const hideFilterButton = () => {

            const filterClearBtn = document.querySelector('.filter-clear');
            const filterBackBtn = document.querySelector('.filter-back');
            const filterDoneBtn = document.querySelector('.filter-done');
            const filterClearAllBtn = document.querySelector('.filter-clear-all');

            [filterBackBtn, filterClearBtn].forEach((item) => {
                item.classList.add('d-none');
            });
            [filterDoneBtn, filterClearAllBtn].forEach((item) => {
                item.classList.remove('d-none');
            });
        }


        document.addEventListener('click', function (e) {
            const item = e.target.closest('.filter-clear');
            if (item === null) return;
            // filterClearBtn.addEventListener('click', function (e) {

            e.preventDefault();
            const currentFilterElm = document.querySelector('.open-submenu');
            const currentFilterItems = currentFilterElm.querySelectorAll('.submenu__filter_item');

            body.classList.add('content--loading');
            currentFilterItems.forEach((item) => {
                item.classList.remove('current');
            });

            const currentFilterBlock = currentFilterElm.querySelector('.filter__menu_sub-menu');
            const currentFilterOptionsBlock = currentFilterElm.querySelector('.filter__menu_item-options');
            let params = new URLSearchParams(window.location.search);

            currentFilterOptionsBlock.textContent = '';
            currentFilterElm.classList.remove('has--options');
            params.delete(currentFilterBlock.getAttribute('id'));

            axiosRequest(params);

            delete objectsObject[currentFilterBlock.getAttribute('id')];

        });

        document.addEventListener('click', function (e) {
            const item = e.target.closest('.filter-clear-all');
            if (item === null) return;
            //filterClearAllBtn.addEventListener('click', function (e) {

            const filterAllItems = document.querySelectorAll('.submenu__filter_item');

            filterAllItems.forEach((item) => {
                item.classList.remove('current');
            });

            body.classList.add('content--loading');
            axiosRequest('page=1');
            objectsObject = {};

            localStorage.removeItem("filterMobileSelected");
            localStorage.removeItem("filtersOpenSubmenu");

            const filterOptionsElms = document.querySelectorAll('.filter__menu_item-options');
            const filterItemsElms = document.querySelectorAll('.filter__menu_item');

            filterOptionsElms.forEach((item) => {
                item.textContent = '';
            });
            filterItemsElms.forEach((item) => {
                item.classList.remove('has--options');
            });

        });

        // [filterBackBtn, filterChooseBtn].forEach((item) => {

            document.addEventListener('click', function (e) {
                // const item = e.target.closest('.filterBackBtn');
                if (e.target.closest('.filter-back') !== null || e.target.closest('.filter-choose') !== null ) {

                    // item.addEventListener('click', function (e) {
                    e.preventDefault();
                    hideFilterButton();
                    document.querySelector('.filter__menu').classList.remove('submenu-open');
                    document.querySelector('.open-submenu').classList.remove('open-submenu');

                    filterBackBtn.classList.add('d-none');
                    filterChooseBtn.classList.add('d-none');

                    const wrapWithSubmenu = document.querySelectorAll('.with-submenu-wrap');
                    wrapWithSubmenu.forEach((item) => {
                        item.removeAttribute('style');
                    });
                }
            });
        // });

        // filterOpenTriggerElms.forEach((item) => {
            document.addEventListener('click', function (e) {


                const item = e.target.closest('.filter__menu_item_trigger');
                if (item === null) return;
                //
                // const wrapWithScroll = document.querySelectorAll('.wrap--custom');
                // if (wrapWithScroll.length > 0) {
                //
                //     wrapWithScroll.forEach((item) => {
                //         const simpleBar = new SimpleBar(item);
                //         simpleBar.recalculate();
                //     });
                //
                // }

                const currentFilter = item.parentNode.dataset.item;

                e.preventDefault();
                const windowWidth = window.innerWidth;

                if (item.parentNode.classList.contains('open-submenu')) {  //.closest('.filter__menu_item')
                    item.parentNode.classList.remove('open-submenu');
                    item.closest('.filter__menu').classList.remove('submenu-open');

                    if (windowWidth >= 1200) {
                        slideUp(item.closest('.filter__menu_item').querySelector('.with-submenu-wrap'), 300)
                        filtersOpenList = filtersOpenList.filter(item => item !== currentFilter);
                    } else {
                        hideFilterButton();
                    }

                    localStorage.removeItem("filtersOpenSubmenu");
                    // SimpleBar.removeObserver();
                    // new SimpleBar(document.querySelector('.with-submenu-wrap'));
                    // item.closest('.with-submenu').querySelector('.sub-menu').classList.remove('d-block');
                } else {
                    item.parentNode.classList.add('open-submenu');
                    item.closest('.filter__menu').classList.add('submenu-open');

                    if (windowWidth >= 1200) {
                        slideDown(item.closest('.filter__menu_item').querySelector('.with-submenu-wrap'), 300)
                        filtersOpenList.push(currentFilter);
                    } else {
                        showFilterButton();
                    }
                    // item.closest('.with-submenu').querySelector('.sub-menu').classList.add('d-block');

                    localStorage.setItem("filtersOpenSubmenu", 'true');

                }

                localStorage.setItem("filtersOpenList", filtersOpenList);
            });
        // });

        // Array.prototype.forEach.call(
        //     document.querySelector('.with-submenu-wrap'),
        //     (el) => new SimpleBar(el)
        // );

        // const scroll = document.querySelectorAll('.wrap--custom')
        //
        // scroll.forEach((item) => {
        //     console.log('item', item)
        //     const simpleBar = new SimpleBar(item);
        //     simpleBar.recalculate();
        // })

        let objectsObject = {};

        getResponseParams(objectsObject);

        function updateObjectsObject(key, value) {
            // Проверяем, существует ли уже массив значений для данного ключа
            if (objectsObject.hasOwnProperty(key)) {
                // Если существует, проверяем, есть ли уже такое значение
                if (!objectsObject[key].includes(value)) {
                    // Если значения нет, добавляем его в массив
                    objectsObject[key].push(value);
                } else {
                    // Если значение уже существует, удаляем его из массива
                    objectsObject[key] = objectsObject[key].filter(item => item !== value);
                    // if (objectsObject[key].length === 0) {
                    //     delete objectsObject[key];
                    // }
                }
            } else {
                // Если массива значений для данного ключа нет, создаем новый массив и добавляем значение в него
                objectsObject[key] = [value];
            }
        }

        filterItemElms.forEach((item) => {
            let optionsList = [];
            const filterItemOptionsElm = item.querySelector('.filter__menu_item-options');
            const filterItemActiveElms = item.querySelectorAll('.current');

            if (filterItemActiveElms.length > 0) {
                item.classList.add('has--options');
                filterItemActiveElms.forEach((item) => {
                    optionsList.push(item.textContent.replace(/\s/g, ''));
                });

                filterItemOptionsElm.textContent = optionsList.join(', ');
            }
        });




        // filterSubmenuItemElms.forEach((item) => {

        document.addEventListener('click', function (e) {

            const item = e.target.closest('[data-filter-item]');
            if (item === null) return;

            e.preventDefault();

            const filterType = item.dataset.filter;
            const itemID = item.dataset.id;

            updateObjectsObject(filterType, itemID);
            setQueryResult(objectsObject);

            if(item.classList.contains('current')) {
                item.classList.remove('current');
            } else {
                item.classList.add('current');
            }

            const currentFilterElm = item.closest('.filter__menu_item');
            const currentFilterOptionsElm = currentFilterElm.querySelector('.filter__menu_item-options');
            const selectedFilterItems = currentFilterElm.querySelectorAll('.current');
            const currentOptionsLength = currentFilterElm.querySelectorAll('.current').length;
            let optionsArray = [];

            selectedFilterItems.forEach((item) => {
                optionsArray.push(item.textContent.replace(/\s/g, ''));
            });

            currentFilterOptionsElm.innerHTML = optionsArray.join(', ');

            if (currentOptionsLength > 0) {
                currentFilterElm.classList.add('has--options');
            } else {
                currentFilterElm.classList.remove('has--options');
            }

            const windowWidth = window.innerWidth;

            if (windowWidth < 1200) {
                filterBackBtn.classList.add('d-none');
                filterChooseBtn.classList.remove('d-none');
                localStorage.setItem("filterMobileSelected", filterType);
            }

        });
        // });



        // }
        //
        // const filterMobileInit = () => {

        const filterMobileOpenTriggerElm = document.querySelector('.mobile__filter_trigger');
        const filterBlockElm = document.querySelector('.filter__block');
        const pageSidebarElm = document.querySelector('.page__sidebar');


        const headerMenuBackdropElm = document.querySelector('.header__menu_backdrop');
        const filterBlockBodyElm = document.querySelector('.filter__block_body');
        //actions_block_header
        const actionsMobileBlockElm = document.querySelector('.mobile__actions_block');

        document.addEventListener('click', function (e) {

            const item = e.target.closest('.mobile__filter_trigger');
            if (item === null) return;

        // filterMobileOpenTriggerElm.addEventListener('click', function (e) {
            e.preventDefault();

            const filterBlockElm = document.querySelector('.filter__block');
            const filterBlockBodyElm = document.querySelector('.filter__block_body');

            // console.log('filterMobileOpenTriggerElm click')

            const chatBlockElm = document.querySelector('.swift-callback');


            if (filterBlockBodyElm.classList.contains('open')) {
                filterBlockBodyElm.classList.remove('open');

                if (!pageSidebarElm.classList.contains('filter--fixed')) {
                    filterBlockElm.classList.remove('filter__block--fixed');
                    pageSidebarElm.classList.remove('page__sidebar--fixed');
                }

                body.classList.remove('modal__open');
                // filterBlockBodyElm.classList.add('d-none');
                headerMenuBackdropElm.classList.remove('open');
                filterBlockElm.classList.remove('mobile__filter-open');
                if (chatBlockElm) {
                    chatBlockElm.classList.remove('d-none');
                }

                localStorage.removeItem("mobileFilterTrigger");

            } else {
                filterBlockBodyElm.classList.add('open');

                filterBlockElm.classList.add('filter__block--fixed');
                pageSidebarElm.classList.add('page__sidebar--fixed');

                body.classList.add('modal__open');
                // filterBlockBodyElm.classList.remove('d-none');
                headerMenuBackdropElm.classList.add('open');
                filterBlockElm.classList.add('mobile__filter-open');
                filterBlockElm.classList.remove('mobile__sort-open');
                if (chatBlockElm) {
                    chatBlockElm.classList.add('d-none');
                }

                localStorage.setItem("mobileFilterTrigger", "true");

            }
        });


        // [filterDoneBtn, headerMenuBackdropElm].forEach((item) => {

            document.addEventListener('click', function (e) {

                // console.log('filterDoneBtn click 1')
                // console.log(e.target.closest('.filter-done') === null)
                // const item = e.target.closest('.filterBackBtn');
                if (e.target.closest('.filter-done') !== null || e.target.closest('.header__menu_backdrop') !== null ) {

                    // console.log('filterDoneBtn click 2')
                // item.addEventListener('click', function (e) {
                    e.preventDefault();

                    const filterBlockElm = document.querySelector('.filter__block');
                    const filterBlockBodyElm = document.querySelector('.filter__block_body');

                    filterBlockBodyElm.classList.remove('open');

                    const chatBlockElm = document.querySelector('.swift-callback');
                    if (chatBlockElm && chatBlockElm.classList.contains('d-none')) chatBlockElm.classList.remove('d-none');

                    if (!pageSidebarElm.classList.contains('filter--fixed')) {
                        filterBlockElm.classList.remove('filter__block--fixed');
                        pageSidebarElm.classList.remove('page__sidebar--fixed');
                    }

                    body.classList.remove('modal__open');
                    // filterBlockBodyElm.classList.add('d-none');
                    headerMenuBackdropElm.classList.remove('open');

                    // console.log('filterBlockElm', filterBlockElm)
                    filterBlockElm.classList.remove('mobile__filter-open', 'mobile__sort-open');
                }

            });
        // });


        const addFixedClass = () => {
            const filterBlockOffset = filterBlockElm.offsetTop;
            if (window.scrollY > filterBlockOffset) {
                filterBlockElm.classList.add('filter__block--fixed');
                pageSidebarElm.classList.add('page__sidebar--fixed');
                pageSidebarElm.classList.add('filter--fixed');
            } else {
                filterBlockElm.classList.remove('filter__block--fixed');
                pageSidebarElm.classList.remove('page__sidebar--fixed');
                pageSidebarElm.classList.remove('filter--fixed');
            }

        }

        addFixedClass();

        // on documment scroll
        document.addEventListener('scroll', function (e) {
            addFixedClass();
        });

        window.addEventListener('resize', function (e) {

            const windowWidth = window.innerWidth;
            const currentFilterItemsElms = document.querySelectorAll('.filter__menu_item');
            const filterMenuElm = document.querySelector('.filter__menu');

            //
            // filterMenuElm.classList.remove('submenu-open');
            // currentFilterItemsElms.forEach((item) => {
            //     if (item.classList.contains('open-submenu')) {
            //         item.classList.remove('open-submenu');
            //     }
            // });

            if (windowWidth < 1200) {
                filterMenuElm.classList.remove('submenu-open');
                currentFilterItemsElms.forEach((item) => {
                    if (item.classList.contains('open-submenu')) {
                        item.classList.remove('open-submenu');
                    }
                });
                hideFilterButton();

                // if (wrapWithScroll.length > 0) {
                //
                //     console.log('wrapWithScroll1', wrapWithScroll.length)
                //     wrapWithScroll.forEach((item) => {
                //         item.removeObserver();
                //     });
                // }

            } else {
                // const wrapWithScroll = document.querySelectorAll('.wrap--custom');
                // if (wrapWithScroll.length > 0) {
                //
                //     wrapWithScroll.forEach((item) => {
                //         const simpleBar = new SimpleBar(item);
                //         simpleBar.recalculate();
                //     });
                //
                // }
            }


        });

        const windowWidth = window.innerWidth;
        const itemWithSubmenuElms = document.querySelectorAll('.with-submenu');
        if (windowWidth < 1200) {
            itemWithSubmenuElms.forEach((item) => {
                item.classList.remove('open-submenu');
            });
        } else {

            // const wrapWithScroll = document.querySelectorAll('.wrap--custom');
            // if (wrapWithScroll.length > 0) {
            //
            //     wrapWithScroll.forEach((item) => {
            //         const simpleBar = new SimpleBar(item);
            //         simpleBar.recalculate();
            //     });
            //
            // }
        }


        const wrapWithScroll = document.querySelectorAll('.wrap--custom');
        if (wrapWithScroll.length > 0) {
            wrapWithScroll.forEach((item) => {
                const simpleBar = new SimpleBar(item);
                simpleBar.recalculate();
            });

        }
    }

    return {
        filterHomeInit,
        filterSidebarInit,
        // filterMobileInit,
        // filterReset
    }
}

export default filtersInit;
