import axios from "axios";
import getDataInit from "./get-data";

const customSelectInit = (item = null)=> {

    let x, i, j, l, ll, selElmnt, a, b, c;
    /* Look for any elements with the class "custom-select": */
    x = item ? document.querySelectorAll(item) : document.querySelectorAll(".custom__select");

    // console.log('x', x)
    l = x.length;
    for (i = 0; i < l; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        /* For each element, create a new div that will act as the selected item: */
        a = document.createElement("div");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        /* For each element, create a new div that will contain the option list: */
        b = document.createElement("div");
        b.setAttribute("class", "select-items select-hide");
        for (j = 0; j < ll; j++) {
            /* For each option in the original select element,
            create a new div that will act as an option item: */
            c = document.createElement("div");
            c.innerHTML = selElmnt.options[j].innerHTML;
            c.setAttribute('data-value', selElmnt.options[j].value);
            // c.setAttribute('data-param', selElmnt.options[j].dataset.param);

            // if selected
            if (selElmnt.options[j].getAttribute('selected') !== null) {
                c.setAttribute('class', 'same-as-selected');
            }
            c.addEventListener("click", function(e) {
                /* When an item is clicked, update the original select box,
                and the selected item: */
                let y, i, k, s, h, sl, yl;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                sl = s.length;
                h = this.parentNode.previousSibling;
                for (i = 0; i < sl; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        yl = y.length;
                        for (k = 0; k < yl; k++) {
                            y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
                h.click();

                const { getData } = getDataInit();
                getData('sort', this.dataset.value);

            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function(e) {
            /* When the select box is clicked, close any other select boxes,
            and open/close the current select box: */
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");


            // const mobileActionsBlockElm = this.closest('.mobile__actions_block');
            const filterBlockElm = document.querySelector('.filter__block');
            const pageSidebarElm = document.querySelector('.page__sidebar');
            const filterBlockBodyElm = document.querySelector('.filter__block_body');
            const body = document.querySelector('body');

            const chatBlockElm = document.querySelector('.swift-callback');

            // if (filterBlockElm !== null) {
                const headerMenuBackdropElm = document.querySelector('.header__menu_backdrop');

                if (filterBlockElm.classList.contains('mobile__sort-open')) {
                    filterBlockElm.classList.remove('open');

                    if (!pageSidebarElm.classList.contains('filter--fixed')) {
                        filterBlockElm.classList.remove('mobile__sort-open');
                        pageSidebarElm.classList.remove('page__sidebar--fixed');
                    }


                    // filterBlockElm.classList.remove('mobile__sort-open');
                    // pageSidebarElm.classList.remove('page__sidebar--fixed');


                    body.classList.remove('modal__open');
                    headerMenuBackdropElm.classList.remove('open');

                    chatBlockElm.classList.remove('d-none');

                } else {
                    filterBlockElm.classList.remove('mobile__filter-open');

                    body.classList.add('modal__open');
                    filterBlockElm.classList.add('mobile__sort-open');
                    pageSidebarElm.classList.add('page__sidebar--fixed');
                    filterBlockBodyElm.classList.remove('open');
                    // filterBlockElm.classList.remove('open');
                    // filterBlockElm.classList.add('d-none');
                    headerMenuBackdropElm.classList.add('open');

                    chatBlockElm.classList.add('d-none');
                }
            // }

        });
    }

    function closeAllSelect(elmnt) {
        /* A function that will close all select boxes in the document,
        except the current select box: */
        let x, y, i, xl, yl, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        xl = x.length;
        yl = y.length;
        for (i = 0; i < yl; i++) {
            if (elmnt == y[i]) {
                arrNo.push(i)
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (i = 0; i < xl; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
            }
        }
    }


    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);
}

export default customSelectInit;
